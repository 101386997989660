<template lang="pug">
  .quick-start-page
    .header 
      .nio-jumbo-2.text-white Seller Studio
      .nio-p-large.text-primary-light Create data streams and access rules from your datasets.
      NioButton(
        normal-primary 
        @click="newSubscription"
      ) New Data Stream
      .graphic
        img(src="https://cdn.narrative.io/data-studio/images/seller-quick-start-hero-flat.svg")
    .steps-section
      h1.heading.nio-h1.text-primary-darker Create Data Streams
      .steps
        .step(
          v-for="step of steps"
        )
          .image(
            :style="{backgroundColor: '#E9F2FF'}"
          )
            img(
              :src="step.image"
            )
          .heading.nio-h4.text-primary-darker {{ step.heading }}
          .description.nio-p.text-primary-dark {{ step.description }}
</template>

<script>

import { formatNumberVerbose } from '../../modules/helpers'

export default {
  data: () => ({
    stats: [
      {
        name: 'Source',
        value: 44,
        annotation: "Providers"
      },
      {
        name: 'Signals',
        value: 2860000,
        annotation: "Events"
      },
      {
        name: 'Mobile Identifiers',
        value: 386000000,
        annotation: "Mobile IDs"
      },
      {
        name: 'PC Identifiers',
        value: 434000000,
        annotation: "Cookie IDs"
      },
      {
        name: 'Identifiers',
        value: 621000000,
        annotation: "Hashed Emails"
      }
    ],
    steps: [
      {
        image: 'https://cdn.narrative.io/data-studio/images/seller-quick-start-choose-attributes.svg',
        heading: 'Choose fields',
        description: "Select fields from your dataset."
      },
      {
        image: 'https://cdn.narrative.io/data-studio/images/seller-quick-start-set-price.svg',
        heading: 'Set your price',
        description: "Set a price for every 1000 records of raw data."
      },
      {
        image: 'https://cdn.narrative.io/data-studio/images/seller-quick-start-choose-visibility.svg',
        heading: 'Choose visibility',
        description: "Send your data to one or more locations."
      },
      {
        image: 'https://cdn.narrative.io/data-studio/images/seller-quick-start-activate.svg',
        heading: 'Activate data stream',
        description: "Change your data stream settings anytime."
      }
    ]
  }),	
  methods: {
    newSubscription() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    formatNumber(val) {
      return formatNumberVerbose(val)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.quick-start-page
  display: flex
  flex-direction: column
  align-items: stretch
  .header
    padding: 64px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    background-color: #005046
    .graphic
      width: 596px
      height: 540px
      margin-bottom: -24px
      margin-top: -24px
      img
        width: 100%
        height: 100%
    .nio-p-large
      margin-top: 8px
    .nio-button
      margin-top: 24px
      margin-bottom: 48px
  .steps-section
    padding: 64px 24px
    & > .heading, & > .description
      text-align: center
    & > .description
      margin-top: 8px
    .steps
      margin-top: 56px
      padding: 0px 20px
      display: flex
      justify-content: space-around
      .step
        width: 200px
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        flex-basis: 0
        text-align: center
        .image
          width: 160px
          height: 160px
          border-radius: 50%
          display: flex
          justify-content: center
          align-items: center
          border: 2px solid $c-primary-light
          .graphic
            width: 40px
            height: 40px
        .heading
          margin-top: 16px
        .description
          margin-top: 16px
</style>